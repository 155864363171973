import { Check } from '@styled-icons/remix-fill';
// import { CheckmarkCircle2Outline } from '@styled-icons/evaicons-outline';
import { Icon, IconProps } from './Icon';

export function CheckIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Check />
    </Icon>
  );
}
